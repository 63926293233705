import React, { useEffect, useState } from 'react';
import L from 'leaflet';
import "leaflet-routing-machine";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";

function ModalMastensysteme() {

    const [tourinfo, SetTourinfo] = useState("");

    useEffect(() => {
        //const tourinfo = "";
        // create map
        const map = L.map('map').setView([51.22324227472704, 6.287198098389031], 8);

        // add tile layer
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 20,
            zoom: 10,
        }).addTo(map);

        // add waypoints

        const waypointinfos = [];

        const waypoints = [];
        waypoints.push([51.22324227472704, 6.287198098389031, "Home"]);
        waypointinfos.push("Home:Home");

        const stringifiedStandorte = localStorage.getItem('mastensysteme');
        const standorte = JSON.parse(stringifiedStandorte);

        for(let i=0; i<standorte.length; i++){
            const lat = standorte[i].lat;
            const lng = standorte[i].lng;
            if (lat!==0) {
                waypoints.push([lat, lng, standorte[i].name, standorte[i].gewichte, standorte[i].adresse]);
                waypointinfos.push(standorte[i].name+" - Gewichte: "+standorte[i].gewichte);
            }
        }

        const markerIcon = L.icon({
            iconUrl:'../assets/marker-icon.png',
            iconRetinaUrl:'../assets/marker-icon-2x.png',
            iconSize:[20, 33],
            iconAnchor:[12.5, 31],
            popupAnchor:[0, -25],
        });


        var marker = markerIcon;
        var markers = [];

        waypoints.forEach(function(waypoint) {
            var marker = L.marker([waypoint[0], waypoint[1]],{icon: markerIcon, alt: waypoint[2]},)
                .addTo(map)
                .bindPopup(waypoint[2]+" - <strong>"+waypoint[3]+"</strong> Gewichte<br><br>"+ waypoint[4]);
            markers.push(marker);
            var markerGroup = L.layerGroup(markers);
            map.addLayer(markerGroup);

            var bounds = L.latLngBounds(markers.map(function(marker) {
                return marker.getLatLng();
            }));
            map.fitBounds(bounds);
        });



    }, [])




        return (
            <>
            <div className='karte'>
                <div id="map" style={{ width: '100%', height: '97%' }}></div>
            </div>
                <div className="toureninfo">{tourinfo}</div>
            </>
        );

}

export default ModalMastensysteme;
