import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Wetter = () => {
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [weather, setWeatherData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Funktion zum Abrufen der geografischen Koordinaten
        const getGeolocation = () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    position => {
                        setLatitude(position.coords.latitude);
                        setLongitude(position.coords.longitude);
                    },
                    error => {
                        console.error('Error getting geolocation:', error);
                    }
                );
            } else {
                console.error('Geolocation is not supported by this browser.');
            }
        };

        // Funktion zum Abrufen des Wetters basierend auf den Koordinaten
        const getWeatherData = async () => {
            const apiKey = '86e5016fc1fce3ad7a988eb0b5a1c67c';
            const apiUrl = `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=${apiKey}&units=metric`;

            try {
                const response = await axios.get(apiUrl);
                setWeatherData(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error getting weather data:', error);
            }
        };

        getGeolocation();
        getWeatherData();
    }, [latitude, longitude]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!weather) {
        return <div>Unable to fetch weather data.</div>;
    }

    return (
        <div className="content">
            <div className="city">
                <h2 className="city-name">
                    <span>Aktuelles Wetter in {weather.name}</span>
                    <sup>{weather.sys.country}</sup>
                </h2>
                <div className="city-temp">
                    {Math.round(weather.main.temp)}
                    <sup>&deg;C</sup>
                </div>
                <div className="info">
                    <img className="city-icon"
                         src={`https://openweathermap.org/img/wn/${weather.weather[0].icon}@2x.png`}
                         alt={weather.weather[0].description}/>
                    <p>{weather.weather[0].description}</p>
                </div>
            </div>
        </div>
    );
};

export default Wetter;
