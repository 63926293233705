import React, { useState } from 'react';
import Webcam from "react-webcam";
import {Button} from '@material-ui/core';


const videoConstraints = {
    facingMode: "environment",
};


function Makefoto (){

    const [image,setImage]=useState('');
    const webcamRef = React.useRef(null);

    const capture =
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            setImage(imageSrc)
        };

    return (
        <div className="content">
            <h2>Foto aufnehmen</h2>

            <div className="webcam-container">
                <div className="webcam-img">

                    {image === '' ? <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        height = {100 + '%'}
                        width = {100 + '%'}
                        videoConstraints={videoConstraints}
                        className="webcam"

                    /> : <img src={image} alt="ALT" />}
                </div>
                <div className="ImageCam">

                    {image !== '' ?
                        <Button variant="contained"  color="secondary" size="small" onClick={(e) => {
                            e.preventDefault();
                            setImage('')
                        }}
                                className="webcam-btn">
                            Retake Image</Button> :
                        <Button variant="contained" color="secondary" size="small" onClick={(e) => {
                            e.preventDefault();
                            capture();
                        }}
                                className="webcam-btn">Capture</Button>
                    }
                </div>
            </div>


        </div>
    );
}
export default Makefoto;
