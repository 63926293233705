import React, { Component } from 'react';

class Map extends Component {

    componentDidMount() {

        console.log(this.props.modalLat);
        var latitude  = parseFloat(this.props.modalLat);
        var longitude = parseFloat(this.props.modalLng);

        // Erstellen einer neuen Google-Map
        const map = new window.google.maps.Map(this.refs.map, {
            center: {lat: latitude, lng: longitude},
            zoom: 12
        });

        // Erstellen und Hinzufügen des Markers zur Karte
        new window.google.maps.Marker({
            position: {lat: latitude, lng: longitude},
            map: map,
            title: 'Mein Marker'
        });

    }

    render() {
        return (
            <div ref="map" style={{height: '90%', width: '100%'}}></div>
        );
    }
}

export default Map;
