import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import './App.css';

import MyContextProvider from './contexts/MyContext';
import Home from './components/Home';
import Navbar from './components/Navbar';
import Tour from "./components/Tour";
import Tourdaten from "./components/Tourdaten";
import Tourenliste from "./components/Tourenliste"
import Makefoto from "./components/Makefoto";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import Tourladen from "./components/Tourladen";
import Slotzeiten from "./components/Slotzeiten";
import Wetter from "./components/Wetter";
import Hilfe from "./components/Hilfe";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDeIYcDEt2tlHQEs3g-Yjy7hbelhh8Z9T8",
    authDomain: "touren-app.firebaseapp.com",
    projectId: "touren-app",
    storageBucket: "touren-app.appspot.com",
    messagingSenderId: "699021317768",
    appId: "1:699021317768:web:f1556995d4e597e4bab13d",
    measurementId: "G-GFJ600VFYB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  return (
    <MyContextProvider>
        <div className="App">
            <Navbar />
            <Router>
                <Switch>
                    <Route path="/tour" component={Tour} />
                    <Route path="/tourdaten" component={Tourdaten} />
                    <Route path="/tourdaten" component={Tourdaten} />
                    <Route path="/tourenliste" component={Tourenliste} />
                    <Route path="/foto" component={Makefoto} />
                    <Route path="/tourladen" component={Tourladen} />
                    <Route path="/slotzeiten" component={Slotzeiten} />
                    <Route path="/wetter" component={Wetter} />
                    <Route path="/hilfe" component={Hilfe} />
                    <Route path="/" component={Home} />
                </Switch>
            </Router>

        </div>
    </MyContextProvider>
  );
}

export default App;
