import React, {useContext} from "react";
import {slide as Menu} from "react-burger-menu";

import {MyContext} from '../contexts/MyContext'

function Navbar(){
    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth} = rootState;
    if(isAuth){
        return (
            <div className="navbar">
                <div className="titel">Tour-APP</div>
                <Menu>
                    <a className="menu-item" href="/">Home</a>
                    <div></div>
                    <a className="menu-item" href="/tourenliste">Meine Touren</a>
                    <a className="menu-item" href="/hilfe">Hilfe</a>
                    <div></div>
                    <a className="menu-item" href="/" onClick={logoutUser}>Logout</a>
                </Menu>
            </div>
        )
    } else {
        return (
            <div className="navbar">
                <div className="titel">Tour-APP</div>
                <Menu>
                    <a className="menu-item" href="/">Home</a>
                </Menu>
            </div>
        )
    }
}

export default Navbar;
