import React, {useState} from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "@mui/material/Button";
import db from './db'
import Compressor from 'compressorjs';
import axios from "axios";


function Filedialog(props) {
    const [imageBase64, setImageBase64] = useState('');
    const [bildnummer, setBildnummer] = useState('');

    //const [Bild1Vorhanden, setBild1Vorhanden] = useState(false);
    //const [compressedFile, setCompressedFile] = useState(null);

    const [openFile, setDialogOpen] = React.useState(false);
    const handleClickDialogOpen = () => {
        setDialogOpen(true);
        setBildnummer(props.bildnummer);
    };


    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleFile = (e) => {
        const file = e.target.files[0];
        new Compressor(file, {
            quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
            success: (compressedResult) => {
                //console.log(compressedResult);

                //setCompressedFile(compressedResult);
                if (compressedResult) {
                    const reader = new FileReader();
                    reader.readAsDataURL(compressedResult);
                    reader.onload = () => {
                        setImageBase64(reader.result);
                    };
                }
            },
        });



    };


    const updateBilder = () => {
        try {
            db.bilder.where('vid').equals(parseInt(props.vid)).modify((record) => {
                // Aktualisiere den Datensatz hier
                if (bildnummer==="1") record.bild1 = imageBase64;
                if (bildnummer==="2") record.bild2 = imageBase64;
                if (bildnummer==="3") record.bild3 = imageBase64;
                if (bildnummer==="4") record.bild4 = imageBase64;
            });
            console.log("Datensätze wurden aktualisiert.");
        } catch (error) {
            console.error(error);
        }

    }

    const saveBildDaten = () => {
        const loginToken = localStorage.getItem('loginToken');

        axios.post("https://api.bauschild-portal.de/restful-api-webapp/saveBildDaten.php", {
            imageBase64:imageBase64,
            bildnummer:bildnummer,
            tourid:props.tourid,
            vid:props.vid,
            token:loginToken,
        })
            .then((response) => {
                console.log(response.data);
                setDialogOpen(false);
                db.schilder.update(parseInt(props.vid), { foto: imageBase64 });
            });



        updateBilder();

        if (bildnummer==="1"){props.onBild1Change(true);props.onShowBild1(<img src={imageBase64} alt="Baustellenfoto" />);}
        if (bildnummer==="2"){props.onBild2Change(true);props.onShowBild2(<img src={imageBase64} alt="Baustellenfoto" />);}
        if (bildnummer==="3"){props.onBild3Change(true);props.onShowBild3(<img src={imageBase64} alt="Baustellenfoto" />);}
        if (bildnummer==="4"){props.onBild4Change(true);props.onShowBild4(<img src={imageBase64} alt="Baustellenfoto" />);}
        setDialogOpen(false);
    }

    return (
            <>
                <Dialog
                    open={openFile}
                    onClose={handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Fileupload
                    </DialogTitle>
                    <DialogContent>

                        <label>Bilddatei</label>
                        <input type="file" name="foto" onChange={(e)=>handleFile(e)} />
                        <Button variant="outlined" onClick={saveBildDaten}>Übernehmen</Button>


                        <DialogContentText id="alert-dialog-description">
                            Bild Nr. {props.bildnummer} übertragen
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose}>Abbrechen</Button>

                    </DialogActions>
                </Dialog>
                <Button variant="outlined" onClick={handleClickDialogOpen}>Foto hochladen</Button>
            </>
        );

}

export default Filedialog;
