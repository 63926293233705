import React, { useState } from 'react';
import { Button, Modal } from '@material-ui/core';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TTRoute from './TTRoute';

const TTRoutemodal = ({ modalLat, modalLng }) => {
    const [open, setOpen] = useState(false);
    const handleClose = () => {setOpen(false);};
    const handleOpen = () => {setOpen(true);};



    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1000,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <>


            <Button variant="contained" onClick={handleOpen}>Meine Route</Button>

            <Modal open={open} onClose={handleClose}>
                <div>
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Deine Route
                        </Typography>
                        <TTRoute modalLat={modalLat} modalLng={modalLng} />

                        <div className="grid2 modal">
                            <div className="gridzeile mr">
                            <Button variant="contained" color="secondary" onClick={handleClose}>
                                Schließen
                            </Button>
                            </div>

                            <div></div>
                        </div>
                    </Box>


                </div>
            </Modal>
        </>
    );
};

export default TTRoutemodal;
