import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


const Hilfe = () => {




    return (
        <div className="content">
            <div className="wrp">
                <h2>Hilfethemen</h2>
                <div className="mb"></div>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon/>}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Typography>Tour laden</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <div className="accordioncontent">
                                Die einzelnen Positionen einer Tour werden im Normalfall einmal zu Beginn der Tour geladen und lokal gespeichert.
                                <br/><br/>
                                Beim Aufruf der aktuellen oder einer anderen Tour erscheint immer die Möglichkeit, die Tourdaten erneut zu übertragen. Bitte dies immer nur dann machen, wenn sicher ist, dass die Änderungen an einem Auftrag bereits übertragen wurden.
                                <br/><br/>Es kann vorkommen, dass einige Positionen der Tour beim ersten Laden nicht gefunden werden können. Hier bitte die Zentrale informieren. Die Tour muss dann im Backendsystem aktualisiert werden. Sobald das geschehen ist, die Tourdaten nochmal neu übertragen.
                            </div>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon/>}
                        aria-controls="panel2-content"
                        id="panel2-header"
                    >
                        <Typography>Aufbau oder Abbau abschließen</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Um einen Auftrag abschließen zu können, muss generell vorab ein Foto hochgeladen werden. Bei
                            einem Abbau kann bereits ein Foto vorhanden sein. Wenn nicht sichtbar, dann erst auf
                            "Bild(er) anzeigen" klicken. <br/><br/>
                            Wenn ein Auftrag abgeschlossen werden konnte, dann bitte noch die Anzahl Gewichte eintragen,
                            die da bleiben oder mitgenommen werden.<br/><br/>
                            Wenn ein Auftrag nicht abgeschlossen werden konnte, dann bitte eine Bemerkung eintragen.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon/>}
                        aria-controls="panel2-content"
                        id="panel2-header"
                    >
                        <Typography>Service oder Sonstiges abschließen</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Um einen Service- oder Sonstiges-Auftrag abschließen zu können, muss generell vorab ein Foto hochgeladen werden. Es kann bereits ein Foto vorhanden sein. Wenn nicht sichtbar, dann erst auf
                            "Bild(er) anzeigen" klicken. <br/><br/>
                        </Typography>
                    </AccordionDetails>
                </Accordion>

            </div>
        </div>
    );
};

export default Hilfe;
