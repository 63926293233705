import React, {useContext, useEffect, useState} from 'react'
import {MyContext} from '../contexts/MyContext';
import Button from '@mui/material/Button';

// Importing the Login & Register Componet
import Login from './Login'
import Register from './Register'

function Slotzeiten(){
    const {rootState} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;
    const [slotzeiten, setSlotzeiten] = useState('');
    const [SlotzeitenGeladen, setSlotzeitenGeladen] = useState('');

    const loginToken = localStorage.getItem('loginToken');
    const URL = "https://api.bauschild-portal.de/restful-api-webapp/getSlotzeiten.php?t="+loginToken;

    useEffect(() => {
        const fetchSlotzeiten = async () => {
            const response = await fetch(URL);
            const responseText = await response.text();
            setSlotzeiten(responseText);
        };
            fetchSlotzeiten();
    }, []); // Leere Abhängigkeitsliste

    if(isAuth)
    {
        return(
            <div className="content">
                <div className="contentinline">

                    <div className="mb"></div>
                    <div className="slotimage">
                        <h2>Slotzeiten</h2>
                        <img src={slotzeiten}/>
                    </div>
                </div>
            </div>
        )
    }

    else if(showLogin){
        return <Login/>;
    }
    else{
        return <Register/>;
    }

}

export default Slotzeiten;

