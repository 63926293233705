import {Dexie} from 'dexie';

const db = new Dexie('dbschilder');
db.version(1).stores({
    schilder: '++id, Typ, Zusatz, Nr, bauschildnummer, bauvorhaben_name, schildnr, bauschild_bemerkungen, what3words, latlong, bauvorhaben, aufbaudatum, abbaudatum, mietende, aufbaudatumValue, abbaudatumValue, _ID_Vermietung, kunde, foto, design, css, Gewicht_Abbau, Gewicht_Aufbau, tourid, fmuebetragen, istuebertragen, mappe, dokumente',
    materiallisten: 'tourid, Materialliste, LogAngelegt, Tourname',
    bemerkungen: '++id, tourid, vid, uid, user, bemerkung, uebertragen, auftragsart',
    bilder: '++id, tourid, vid, uid, bild1, bild2, bild3, bild4'
});

export default db;
