import React, {useContext, useEffect, useState} from 'react'
import {Link} from "react-router-dom";
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import TextField from '@mui/material/TextField';
import {MyContext} from '../contexts/MyContext';
import Tourenliste from "./Tourenliste";
import LoadModalMasten from "./LoadModalMasten";
import Slotzeiten from "./Slotzeiten";

// Importing the Login & Register Componet
import Login from './Login'
import Register from './Register'
import axios from "axios";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function Home(){

    const {rootState} = useContext(MyContext);
    const {isAuth,theUser,showLogin,tourID,kennzeichen,tuevbis,inspektion,kmstand,kfzid} = rootState;
    const loginToken = localStorage.getItem('loginToken');
    const URL = "https://api.bauschild-portal.de/restful-api-webapp/mastensysteme.php?t="+loginToken;
    const [mastensysteme, setMastensysteme] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [MastenGeladen, setMastenGeladen] = useState('');
    const [kmstanduebermittelt, setkmstanduebermittelt] = useState('');
    const [kmstandHinweis, setkmstandHinweis] = useState('');
    const [kilometerstand, setKilometerstand] = useState(0);
    const [open, setOpen] = React.useState(false);
    const todayIsFriday = new Date().getDay() === 5;

    useEffect(() => {
        const mastensysteme = localStorage.getItem('mastensysteme');
        if (!mastensysteme) getMasten();

        var currentDate = new Date();
        var startOfYear = new Date(currentDate.getFullYear(), 0, 4);
        var diff = currentDate - startOfYear;
        var week = Math.ceil(diff / (7 * 24 * 60 * 60 * 1000));

        setkmstandHinweis('Diesen bitte jede Woche (zum Ende eurer Tour) eingeben. Die Tour endet immer dann, wenn ihr wieder hier am Hof seid. Bei Touren mit 2 Steps, so endet die Tour auch dann nach dem Ende von Step 2. Bei Urlaub oder Krankheit, bitte den KM-Stand freitags aktualisieren.');
    }, []);
    const getMasten = async () => {const response = await fetch(URL);
        const jsonData = await response.json();
        console.log(jsonData);
        localStorage.setItem('mastensysteme', JSON.stringify(jsonData));
        setMastensysteme(jsonData);
        setMastenGeladen("Mastenstandorte wurden neu geladen");
    };

    const handleClickOpen = () => {
        setOpen(true);
    };


    const handleClose = () => {
        setOpen(false);
    };

    const handleKilometerstandChange = (event) => {
        setKilometerstand(event.target.value);
    };
    const transferKilometerstand = async () => {
        console.log(kfzid);
        console.log('Kilometerstand:', kilometerstand);
        setkmstanduebermittelt("Aktueller Kilometerstand für diese Woche wurde übermittelt. Beim nächsten Login werden die Daten auch hier aktualisiert.");
        setkmstandHinweis('');
        try {
            axios.post("https://api.bauschild-portal.de/restful-api-webapp/transferKilometerstand.php", {
                kfzid:kfzid,
                token:loginToken,
                kilometerstand:kilometerstand
            })
                .then((response) => {
                    setOpen(false);
                });
        } catch (error) {
            console.error(error);
        }
    }

    // If user Logged in
    if(isAuth)
    {
        return(
            <div className="content">
                <div className="userInfo">
                    <h2>Hallo {theUser.vorname}</h2>
                    {
                        (() => {
                            if(tourID === 0) {
                                return (
                                    <p>In dieser Kalenderwoche ist keine aktuelle Tour geplant. Hier findest du deine Touren.</p>
                                )
                            } else {
                                return (
                                    <p>Hier findest du deine aktuelle Tour.</p>
                                )
                            }
                        })()
                    }
                </div>

                <div className="userInfo">
                    <div className="form-control">
                        {
                            (() => {
                                if(tourID === 0) {
                                    return (
                                        <Link to={`/tourenliste/`} className="button">Zu deinen Touren</Link>
                                    )
                                } else {
                                    return (
                                        <ButtonGroup
                                            orientation="vertical"
                                            aria-label="vertical contained button group"
                                            variant="contained"
                                        >
                                            <Button component={Link} to={`/tourladen/?id=${tourID}`} variant="contained" color="primary">
                                                Zur aktuellen Tour {tourID}
                                            </Button>

                                        </ButtonGroup>
                                    )
                                }
                            })()
                        }
                    </div>
                </div>

                <div className="grid2">
                    <div className="grid2_div_mb scrollheight mr">
                        <div>
                        <div className="child">
                            <h2>Mastenstandorte</h2>
                            <p>Hier kannst du sehen, wo installierte Mastensystem stehen.</p>
                            <ButtonGroup
                                orientation="vertical"
                                aria-label="vertical contained button group"
                                variant="contained"
                            >

                                {(() => {
                                    return (<LoadModalMasten showModal={showModal} />)
                                })()}
                                <Button variant="outlined" color="primary" onClick={getMasten}>
                                    Mastenstandorte neu laden
                                </Button>
                            </ButtonGroup>
                        </div>
                        <div className="child">{MastenGeladen}</div>
                        </div>
                        <div className="child kilometerstand">
                            <h2>Kilometerstand eingeben</h2>
                            <TextField
                                id="kilometerstand"
                                variant="outlined"
                                size="small"
                                value={kilometerstand}
                                onChange={handleKilometerstandChange}
                            />
                            <Button style={{
                            }} variant="outlined" onClick={handleClickOpen}>Jetzt übermitteln</Button>
                           <p className="hinweisachtung">{kmstandHinweis}</p>
                           <p className="hinweis">{kmstanduebermittelt}</p>
                        </div>
                    </div>

                    <div className="grid2_div_mb scrollheight">
                        <div className="child">
                            <h2>Mein Fahrzeug</h2>
                            <div className="grid2_child">
                                <div className="divtitel">Kennzeichen:</div>
                                <div>{kennzeichen}</div>
                            </div>
                            <div className="grid2_child">
                                <div className="divtitel">Termin TÜV:</div>
                                <div>{tuevbis}</div>
                            </div>
                            <div className="grid2_child">
                                <div className="divtitel">Termin Inspektion:</div>
                                <div>{inspektion}</div>
                            </div>
                            <div className="grid2_child">
                                <div className="divtitel">KM-Stand:</div>
                                <div>{kmstand}</div>
                            </div>
                        </div>
                    </div>
                </div>


                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Kilometerstand übermitteln
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Soll der aktuelle Kilometerstand jetzt übermittelt werden?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={transferKilometerstand}>Ja, übermitteln</Button>
                        <Button onClick={handleClose} autoFocus>
                            Abbrechen
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

        )
    }

    else if(showLogin){
        return <Login/>;
    }
    else{
        return <Register/>;
    }

}

export default Home;
