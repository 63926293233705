import React, {useContext, useEffect, useState} from 'react'
import {useHistory} from "react-router-dom";
import {MyContext} from '../contexts/MyContext';
import Button from '@mui/material/Button';

// Importing the Login & Register Componet
import Login from './Login'
import Register from './Register'

function Tourenliste(){
    const {rootState} = useContext(MyContext);
    const {isAuth,theUser,showLogin,tourID} = rootState;
    const [touren, setTouren] = useState([]);
    const [TourenGeladen, setTourenGeladen] = useState('');
    const navigate = useHistory();
    const loginToken = localStorage.getItem('loginToken');
    const URL = "https://api.bauschild-portal.de/restful-api-webapp/tourenliste.php?t="+loginToken;


    useEffect(() => {
        const fetchTouren = async () => {
            const response = await fetch(URL);
            const jsonData = await response.json();
            console.log(jsonData);
            setTouren(jsonData);
            setTourenGeladen("Tourenliste wurde neu geladen");
        };
        fetchTouren();
    }, []); // Leere Abhängigkeitsliste

    const getTouren = async () => {const response = await fetch(URL);
        const jsonData = await response.json();
        localStorage.setItem('tourenliste'+theUser.id, JSON.stringify(jsonData));
        setTouren(jsonData);
        setTourenGeladen("Tourenliste wurde neu geladen");
    };

    function goToTour(id) {
        navigate.push("/tourladen/?id="+id);
    }

    if(isAuth)
    {
        return(
            <div className="content">
                <h2>Meine Touren</h2>
                <div>
                    <table>
                        <thead>
                        <tr>
                            <th>TourID</th>
                            <th>Tourname</th>
                            <th>Tour angelegt</th>
                            <th>KW</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            touren.map((tour,index) => (
                                <tr
                                    key={index}
                                    onClick={() => goToTour(tour.tourID)}
                                    className={tourID === tour.tourID ? 'highlight' : ''}
                                >
                                    <td>{tour.tourID}</td>
                                    <td>{tour.tourname}</td>
                                    <td>{tour.LogAngelegt}</td>
                                    <td>{tour.KW}</td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>


            </div>
        )
    } else if (showLogin) {
        return <Login/>;
    } else {
        return <Register/>;
    }
}

export default Tourenliste;

