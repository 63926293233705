import React, { useEffect, useState } from 'react';
import L from 'leaflet';
import "leaflet-routing-machine";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";

function TTRoute() {

    const [tourinfo, SetTourinfo] = useState("");

    useEffect(() => {
        //const tourinfo = "";
        // create map
        const map = L.map('map').setView([51.22324227472704, 6.287198098389031], 8);

        // add tile layer
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 20,
            zoom: 10,
        }).addTo(map);

        // add waypoints

        const waypointinfos = [];

        const waypoints = [];
        waypoints.push([51.22324227472704, 6.287198098389031, "Home"]);
        waypointinfos.push("Home:Home");


        const stringifiedStandorte = localStorage.getItem('standorte');
        const standorte = JSON.parse(stringifiedStandorte);

        for(let i=0; i<standorte.length; i++){
            var latlong = standorte[i][1].split(",");
            const lat = parseFloat(latlong[0]);
            const lng = parseFloat(latlong[1]);
            if (lat!==0) {
                waypoints.push([lat, lng, standorte[i][0]]);
                waypointinfos.push(standorte[i][0]+":"+standorte[i][2]+":"+standorte[i][3]);
            }
        }
        waypoints.push([51.22324227472704, 6.287198098389031,""]);
        waypointinfos.push("Home:Home");


        const markerIcon = L.icon({
            iconUrl:'../assets/marker-icon.png',
            iconRetinaUrl:'../assets/marker-icon-2x.png',
            iconSize:[20, 33],
            iconAnchor:[12.5, 31],
            popupAnchor:[0, -25],
        });

        const markerIcon_aufbau = L.icon({
            iconUrl:'../assets/marker_aufbau.png',
            iconRetinaUrl:'../assets/marker_aufbau.png',
            iconSize:[20, 33],
            iconAnchor:[12.5, 31],
            popupAnchor:[0, -25],
        });
        const markerIcon_abbau = L.icon({
            iconUrl:'../assets/marker_abbau.png',
            iconRetinaUrl:'../assets/marker_abbau.png',
            iconSize:[20, 33],
            iconAnchor:[12.5, 31],
            popupAnchor:[0, -25],
        });
        const markerIcon_service = L.icon({
            iconUrl:'../assets/marker_service.png',
            iconRetinaUrl:'../assets/marker_service.png',
            iconSize:[20, 33],
            iconAnchor:[12.5, 31],
            popupAnchor:[0, -25],
        });
        const markerIcon_sonstiges = L.icon({
            iconUrl:'../assets/marker_sonstiges.png',
            iconRetinaUrl:'../assets/marker_sonstiges.png',
            iconSize:[20, 33],
            iconAnchor:[12.5, 31],
            popupAnchor:[0, -25],
        });
        var marker = markerIcon;
        var control = L.Routing.control({
            waypoints:waypoints,
            routeWhileDragging:false,
            addWaypoints:false,
            draggableWaypoints:false,
            lineOptions:{
                styles:[{
                    color:'#3f51b5',
                    weight:4,
                    opacity:0.7
                }]
            },
            createMarker: function() {
                var z = 0;
                waypoints.forEach((waypoint) => {
                    var markerdaten = waypointinfos[z].split(":");
                    switch (markerdaten[0]){
                        case "A": marker = markerIcon_aufbau;break;
                        case "W": marker = markerIcon_abbau;break;
                        case "S": marker = markerIcon_service;break;
                        case "X": marker = markerIcon_sonstiges;break;
                        default:  marker = markerIcon;break;
                    }
                    z++;
                    var info = markerdaten[1];
                    if (info === undefined) info = "";
                    return L.marker(waypoint, {icon: marker }).addTo(map).bindPopup(markerdaten[2]+". "+waypoint[2]+": "+info);
                });
            }
        }).on('routesfound', function(e) {
            //console.log(e.routes[0]);

            var distanz = e.routes[0].summary.totalDistance;
            distanz = Math.round(distanz / 1000);

            SetTourinfo("Gesamtstrecke: "+distanz.toLocaleString("de-DE")+" km");
        })
            .addTo(map);

        control.hide();
        map.fitBounds([
            waypoints
        ]);


    }, [])




        return (
            <>
            <div className='karte'>
                <div id="map" style={{ width: '100%', height: '97%' }}></div>
            </div>
                <div className="toureninfo">{tourinfo}</div>
            </>
        );

}

export default TTRoute;
